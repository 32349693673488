.testimonialsSection {
    @apply max-w-6xl mx-auto pb-16;
}
.testiList {
    @apply xl:grid xl:grid-cols-3 xl:gap-10;
}
.testiItem {
    @apply flex flex-col items-center;
}
.iconRate {
    @apply mb-4;
}
.testiTitle {
    @apply max-w-[224px] text-center;
}
.testiComment {
    @apply text-center;
}
.testiAuthor {
    @apply italic;
}
