.customlistSection {
    @apply py-20 bg-[#f6f6f6];
}
.customlistWrap {
    @apply flex flex-col items-center pb-10;
}
.customlistProducts {
    @apply grid grid-cols-1 gap-[51px] mx-auto;
    @apply md:grid-cols-2;
    @apply lg:grid-cols-3;
    @apply xl:max-w-6xl;
}
.customlistLink {
    @apply inline-block;
    @apply text-sm capitalize;
    @apply border border-black rounded-3xl;
    @apply px-6 py-2;
    @apply bg-white;
}
.CardItem {
    @apply flex flex-col items-center pb-6 shadow-lg;
    @apply hover:shadow-2xl;
}
.CardItem:hover .customlistLink {
    @apply bg-blue-600 border-blue-600 text-white;
}
