.briefSection {
    @apply grid grid-cols-1 gap-10 mx-auto py-20;
    @apply md:grid-cols-2;
    @apply xl:max-w-6xl;
}
.briefLink {
    @apply inline-block;
    @apply bg-blue-600 hover:bg-blue-800 text-white;
    @apply capitalize font-light;
    @apply px-6 py-3 rounded-md;
    @apply transition duration-300;
}

.skeletonWrap {
    @apply overflow-hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.skeletonWrap img {
    @apply md:transition-transform md:duration-500 md:ease-in-out;
}
.skeletonWrap:hover img {
    @apply scale-105;
}
