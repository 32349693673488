.SampleSection {
    background: #00bab6;
    @apply grid gap-20 px-8 mx-auto py-16;
    @apply md:grid-cols-7;
    @apply xl:max-w-6xl;
}
.SampleIntro {
    @apply flex flex-col justify-center;
    @apply md:col-span-4;
    @apply xl:pl-12 xl:pr-24;
}
.imgWrap {
    @apply content-center;
    @apply md:col-span-3;
    @apply xl:pr-20;
}
.SampleLink {
    @apply inline-block;
    @apply bg-white text-blue-800;
    @apply capitalize font-medium;
    @apply px-6 rounded-md;
    @apply hover:bg-blue-600 hover:text-white hover:opacity-100;
}
