.faq .faqWrap {
    @apply max-w-6xl mx-auto py-16 px-8 xl:px-0;
}
.faq .faqList {
    @apply xl:columns-2 gap-x-28;
}
.faq .faqItem {
    @apply break-inside-avoid mb-10;
}
.faq .itemTitle {
    @apply font-bold text-lg;
    color: #0096d4;
}
.faq .itemTitle p {
    @apply m-0;
}
