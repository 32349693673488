.image-container {
    position: relative;
    width: 350px; /* 根据需要调整大小 */
    height: 350px; /* 根据需要调整大小 */
    overflow: hidden; /* 确保容器内的内容不会溢出 */
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; /* 确保图片覆盖容器并保持比例 */
    transition: opacity 0.8s ease-in-out; /* 平滑过渡效果 */
}

.visible-image {
    opacity: 1;
    z-index: 2; /* 确保当前显示的图片在上层 */
}

.hidden-image {
    opacity: 0;
    z-index: 1; /* 确保隐藏的图片在下层 */
}
