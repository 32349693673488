.ClickButton {
    @apply bg-blue-600 text-white px-6 py-4 rounded-md shadow transition duration-300 font-medium capitalize !important;
    @apply hover:bg-blue-700 hover:opacity-100 !important;
    @apply text-base !important;
}
.ClickButton::after {
    content: '\00a0\00a0\003e';
}
.Header {
    @apply flex justify-center text-lg font-semibold px-4 pb-6;
}
