.heroSection {
    @apply bg-[#fff514];
    @apply h-[650px] flex items-center justify-center;
}
.heroWrap {
    @apply mx-auto max-w-7xl md:px-8;
}
.heroGrid {
    @apply grid grid-cols-1 md:grid-cols-2 py-6 gap-y-20 md:gap-y-0;
}
.heroHead {
    @apply flex flex-col justify-center items-center sm:items-start px-8 md:px-0 content-center;
}
.heroHeadLink {
    /*@apply inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-blue-600 rounded-md shadow hover:bg-blue-500 capitalize;*/
}
.heroHeadLink {
    @apply bg-blue-600 text-white hover:bg-blue-800 px-6 py-3 rounded-md shadow transition duration-300 font-medium capitalize;
}
.heroPic {
    @apply flex justify-center md:justify-end;
}
.heroPic img {
    @apply xl:min-w-[600px];
}
