.BenefitsSection {
    @apply bg-[#0096d4] px-6;
}
.BenefitsWrap {
    @apply max-w-6xl mx-auto py-16;
    @apply text-white;
    @apply xl:grid xl:grid-cols-2 xl:gap-40;
}
.BenefitsContent {
}
.BenefitsList {
    @apply grid grid-cols-2 gap-10;
}
.BenefitsItem {
    @apply flex items-center;
}
.BenefitsIcon {
    @apply pr-6;
}
