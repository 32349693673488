.introSection {
    @apply bg-white flex flex-col items-center py-10;
}
.introFeatures {
    @apply grid mx-auto gap-10;
    @apply lg:grid-cols-3;
    @apply xl:max-w-5xl;
}
.introWrapper {
    @apply flex flex-col items-center px-6;
    @apply lg:items-start lg:px-0;
}
.introHead {
    @apply flex items-center gap-4;
}
.introHeadIcons {
    @apply max-w-[16px] h-auto;
}
.subHead {
    @apply text-center;
    @apply lg:text-start;
}
.introLink {
    @apply bg-blue-600 text-white hover:bg-blue-800 px-6 py-3 rounded-md shadow transition duration-300 font-medium capitalize;
}
