.certWrapper {
    @apply relative h-[400px] overflow-hidden;
}
.certBgImage {
    @apply absolute inset-0 object-cover w-full h-full;
}
.certWrapper .certBgImage {
    /* transition: transform 0.2s ease-in-out; */
    @apply md:transition-transform md:duration-500 md:ease-in-out;
}
.certWrapper:hover .certBgImage {
    @apply md:scale-105;
}
.certSection {
    @apply mx-auto py-16;
    @apply xl:max-w-6xl;
}
.certLayer {
    /*@apply relative z-10 flex flex-col items-center justify-center h-full text-center text-white bg-black bg-opacity-20;*/
    @apply relative z-10 grid h-full;
    @apply sm:grid-cols-5;
}
.introWrap {
    @apply flex items-center;
    @apply sm:col-span-3 p-4;
    @apply xl:pl-20;
}
.introIcon {
    @apply max-w-[64px] mr-6;
}
.introContent {
    @apply text-white;
}
.introTitle {
    @apply font-extrabold text-4xl pb-2.5;
}
.introText {
    @apply text-sm;
}
.ImgWrap {
    @apply hidden;
    @apply sm:flex justify-center items-center;
    @apply sm:col-span-2;
}
.ImgWrap img {
    @apply max-h-[300px] w-auto h-auto;
}
